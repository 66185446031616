<template>
  <div class="main container">
    <div class="fireworks"></div>
    <img class="banner" src="@/assets/banner.png" alt="tesla" />
    <div class="content">
      <div class="title">
        <template v-if="round === 1">It’s winners time!</template>
        <template v-else>
          Congratulations! <br />
          <div class="title_username">{{ winner.username }}</div>
          You won with ticket number -
          <span class="title_ticket">{{ winner.ticket }}</span>
        </template>
      </div>
      <button
        class="button"
        :disabled="isButtonHidden"
        :class="{ button__hidden: isButtonHidden }"
        @click="handleClickPickWinner"
      >
        <template v-if="round === 1">Pick Winner</template>
        <template v-else>Pick Next Winner</template>
      </button>
    </div>
    <canvas id="canvas-number"></canvas>
    <canvas id="canvas-dots"></canvas>
    <CardsList :cards="prizes" />
  </div>
</template>

<script setup>
import { Fireworks } from 'fireworks-js';
import { computed, onBeforeMount, onUnmounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/store/app';
import CardsList from './components/CardsList.vue';

const store = useAppStore();
const { prizes, round, winner } = storeToRefs(useAppStore());

const show = ref(true);
const isButtonHidden = computed(() => round.value > 5 || !show.value);

const toggleShow = () => {
  show.value = false;
  const showTimeout = setTimeout(() => {
    show.value = true;
  }, 1000);

  const cancelShowTimeout = () => {
    clearTimeout(showTimeout);
  };

  return cancelShowTimeout;
};

const fireworks = () => {
  const fireworks = new Fireworks(document.querySelector('.fireworks'));
  fireworks.start();
};

const handleClickPickWinner = () => {
  store.pickWinner();
  window.confetti({
    particleCount: 150,
    spread: 60,
  });
  toggleShow();

  round.value > 5 && fireworks();
};

onBeforeMount(() => {
  store.getData();
});

watch(round, () => {
  if (round.value > 5) {
    fireworks();
  }
});

onUnmounted(() => {
  toggleShow.cancelShowTimeout();
});
</script>

<style lang="scss">
* {
  font-family: 'Montserrat', sans-serif;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
canvas {
  position: absolute;
  top: 0;
  left: 0;
}

#canvas-number {
  width: 680px;
  height: 420px;
}

#app {
  background: #060e2a;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
}

.content {
  min-height: 550px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
}

.banner {
  max-width: 926px;
  width: 100%;
}

.title {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  text-align: center;

  &_username,
  &_ticket {
    color: #f6da24;
  }

  &_username {
    font-size: 36px;
    font-weight: 900;
    margin: 8px 0;
  }

  &_ticket {
    font-size: 24px;
    font-weight: 600;
  }
}

.button {
  cursor: pointer;
  border: 0px;
  border-radius: 1000px;
  background-color: #069815;
  width: 100%;
  max-width: 288px;
  padding: 14px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  transition: all ease-in-out 0.3s;
  text-align: center;
  margin-top: 30px;
  opacity: 1;

  &__hidden {
    opacity: 0;
    cursor: auto;
  }

  &:hover {
    background-color: #07be1a;
  }
}

.fireworks {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100vh;
}
</style>
