import { defineStore } from 'pinia';
import { ref } from 'vue';
import { api } from '@/api/http';

export const useAppStore = defineStore('counter', () => {
  const round = ref(1);
  const players = ref([]);
  const winners = ref([]);
  const winner = ref({});
  const indexOfWinningTicket = ref(0);
  const prizes = ref([
    {
      place: 5,
      title: 'Iphone 15',
      image: 'iphone',
      username: '',
      ticket: 0,
    },
    {
      place: 4,
      title: 'Iphone 15',
      image: 'iphone',
      username: '',
      ticket: 0,
    },
    {
      place: 3,
      title: 'PSP',
      image: 'psp',
      username: '',
      ticket: 0,
    },
    {
      place: 2,
      title: 'PSP',
      image: 'psp',
      username: '',
      ticket: 0,
    },
    {
      place: 1,
      title: 'Tesla Model 3',
      image: 'tesla',
      username: '',
      ticket: 0,
    },
  ]);

  // Общее кол-во тикетов
  const totalTickets = ref(0);

  const setPrizes = (round, winner) => {
    prizes.value = prizes.value.map((prize) => {
      if (prize.place === round) {
        return (prize = {
          ...prize,
          ...winner,
        });
      }
      return prize;
    });
  };

  const convertRound = (round) => {
    const numberMap = { 5: 1, 4: 2, 3: 3, 2: 4, 1: 5 };
    return numberMap[round];
  };

  const countTotalTickets = () => {
    for (let i = 0; i < players.value.length; i++) {
      totalTickets.value += players.value[i].ticket;
    }
  };
  // Розыгрыш
  const pickWinner = () => {
    indexOfWinningTicket.value = Math.floor(totalTickets.value * Math.random());

    let ticketsToRemove = 0;
    let winnerTicket = 0;
    let winnerData = {};

    for (let i = 0; i < players.value.length; i++) {
      const player = players.value[i];
      player.start -= ticketsToRemove;

      if (
        ticketsToRemove === 0 &&
        player.start - 1 <= indexOfWinningTicket.value &&
        player.start - 1 + player.ticket >= indexOfWinningTicket.value
      ) {
        winnerTicket = player.ticket;
        winnerData = {
          username: player.username,
          score: player.score,
          id: player.id,
          ticket: player.ticket,
          place: convertRound(round.value),
        };

        ticketsToRemove = player.ticket;

        player.start = -1;
        player.ticket = 0;

        totalTickets.value -= winnerTicket;
      }
    }
    if (winnerData.id) {
      postWinner(winnerData);
      setPrizes(convertRound(round.value), winnerData);
      winner.value = winnerData;
      round.value += 1;
    }
  };

  const getPlayers = async () => {
    try {
      const res = await api.get('/players');
      players.value = res.data?.players
        .filter((obj) => !winners.value.some((winner) => obj.id === winner.id))
        .reduce((acc, cur, index) => {
          cur.ticket = Math.floor(cur.score / 100);
          if (index === 0) {
            cur.start = 1;
          } else {
            cur.start = acc[index - 1].start + acc[index - 1].ticket;
          }
          return [...acc, cur];
        }, []);

      countTotalTickets();
    } catch (e) {
      console.log(e);
    }
  };

  const getWinners = async () => {
    try {
      const res = await api.get('/winners');
      winners.value = res.data?.winners.sort((a, b) => b.place - a.place);

      if (winners.value.length) {
        round.value = winners.value.length + 1;
        winner.value = winners.value[winners.value.length - 1];
        for (let i = 0; i < winners.value.length; i++) {
          setPrizes(winners.value[i].place, winners.value[i]);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const postWinner = async (winner) => {
    try {
      await api.put('/winners', winner);
    } catch (e) {
      console.log(e);
    }
  };

  const getData = () => {
    getWinners().then(() => getPlayers());
  };

  return {
    prizes,
    pickWinner,
    round,
    winner,
    winners,
    getData,
    totalTickets,
    players,
    indexOfWinningTicket,
    countTotalTickets,
    convertRound,
  };
});
